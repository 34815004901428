<template>
  <div class="pie">
    <app-echart-doughnut style="width: 200px; height: 200px" :series="series" />
  </div>
</template>

<script>
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";

export default {
  components: {
    AppEchartDoughnut,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      series: [],
    };
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      (this.series[0] = {
        name: "管理房间/床位",
        type: "pie",
        radius: ["35%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        width: 200,
        color: ["#6DCDF5", "#ADE3F9"],
        data: [],
      }),
        (this.series[0].data = this.data);
    },
  },
  watch: {
    data(val) {
      if (val) {
        this.$nextTick(() => {
          this.setData();
        });
      }
    },
  },
};
</script>
