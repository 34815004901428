<template>
  <div class="homeMonitor">
    <b-row>
      <b-col cols="20" sm="9">
        <div class="homeMonitor-left">
          <div class="h-l-title">数据统计 <span>Data Statistics</span></div>
          <div class="pie-list">
            <div class="p-item">
              <div class="pie-title">
                <div>
                  <img src="@/assets/images/pages/home/pie-device.png" />
                </div>
                <div>管理设备数</div>
              </div>
              <div class="pie-center" v-if="allData.deviceData">
                <div class="cl">
                  <div class="txt1">在线：</div>
                  <div class="txt2">
                    {{ allData.deviceData.arr[0].value || "0" }}
                  </div>
                </div>
                <div class="cc">
                  <device :data="allData.deviceData.arr"></device>
                </div>
                <div class="cr">
                  <div>
                    <span class="txt1">离线：</span
                    ><span class="txt2">{{
                      allData.deviceData.arr[1].value || "0"
                    }}</span>
                  </div>
                  <div>
                    <span class="txt1">报警：</span
                    ><span class="txt2">{{
                      allData.deviceData.arr[2].value || "0"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="pie-num txt2 bg-device">
                {{ allData.deviceData.total }}
              </div>
            </div>
            <div class="p-item">
              <div class="pie-title">
                <div>
                  <img src="@/assets/images/pages/home/pie-event.png" />
                </div>
                <div>警报事件</div>
              </div>
              <div class="pie-center" v-if="allData.eventData">
                <div class="cl">
                  <div class="txt1">已处理：</div>
                  <div class="txt2">
                    {{ allData.eventData.arr[0].value || "0" }}
                  </div>
                </div>
                <div class="cc">
                  <event :data="allData.eventData.arr"></event>
                </div>
                <div class="cr">
                  <div class="txt1">待处理：</div>
                  <div class="txt2">
                    {{ allData.eventData.arr[1].value || "0" }}
                  </div>
                </div>
              </div>
              <div class="pie-num txt2 bg-event">
                {{ allData.eventData.total }}
              </div>
            </div>
            <div class="p-item">
              <div class="pie-title">
                <div>
                  <img src="@/assets/images/pages/home/pie-person.png" />
                </div>
                <div>管理人员</div>
              </div>
              <div class="pie-center" v-if="allData.personData">
                <div class="cl">
                  <div class="txt1">已安排：</div>
                  <div class="txt2">
                    {{ allData.personData.arr[0].value || "0" }}
                  </div>
                </div>
                <div class="cc">
                  <person :data="allData.personData.arr"></person>
                </div>
                <div class="cr">
                  <div class="txt1">待分配：</div>
                  <div class="txt2">
                    {{ allData.personData.arr[1].value || "0" }}
                  </div>
                </div>
              </div>
              <div class="pie-num txt2 bg-person">
                {{ allData.personData.total }}
              </div>
            </div>
            <div class="p-item">
              <div class="pie-title">
                <div><img src="@/assets/images/pages/home/pie-room.png" /></div>
                <div>管理房间/床位</div>
              </div>
              <div class="pie-center" v-if="allData.roomData">
                <div class="cl">
                  <div class="txt1">在用：</div>
                  <div class="txt2">
                    {{ allData.roomData.arr[0].value || "0" }}
                  </div>
                </div>
                <div class="cc">
                  <room :data="allData.roomData.arr"></room>
                </div>
                <div class="cr">
                  <div class="txt1">空闲：</div>
                  <div class="txt2">
                    {{ allData.roomData.arr[1].value || "0" }}
                  </div>
                </div>
              </div>
              <div class="pie-num txt2 bg-room">
                {{ allData.roomData.total }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="20" sm="3">
        <div class="homeMonitor-right">
          <div class="h-r-title"><span></span> 最新事件</div>
          <div class="event-list">
            <div class="event-item" v-for="(item, i) in eventList.slice(0, 20)">
              <div class="person">
                <span>{{ formatTime(item.triggerTime, "hh:mm:ss") }}</span>
                <span>{{ item.tenantGroup }}</span>
                <span>{{ item.personName }}</span>
              </div>
              <div class="event">
                {{ eventTypeFormat(item.eventType) }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import device from "./components/device.vue";
import event from "./components/event.vue";
import person from "./components/person.vue";
import room from "./components/room.vue";
import request from "@/api/request";
import validate from "@/utils/validate";
export default {
  name: "homeMonitor",
  components: {
    BRow,
    BCol,
    device,
    event,
    person,
    room,
  },
  data() {
    return {
      allData: {
        deviceData: {
          total: "",
          arr: [],
        },
        eventData: {
          total: "",
          arr: [],
        },
        personData: {
          total: "",
          arr: [],
        },
        roomData: {
          total: "",
          arr: [],
        },
      },
      eventList: [],
      eventTypeOptionsWarning: [],
      eventTypeOptionsNOTIFY: [],
      lvNOTIFY: 3,
      lvWarning: 1,
      eventTypeOptions: undefined,
    };
  },
  mounted() {
    this.eventTypeOptionsFormat();
    this.getData();
  },
  methods: {
    formatTime: validate.formatTime,
    getData() {
      request
        .get("tob/tenant/homeMonitor/getTenantHomeMonitorVo", {})
        .then((res) => {
          if (res.data.code == 200) {
            const data = res.data.data;
            // 设备
            this.allData.deviceData.total = data.deviceVo.totalCount;
            for (let i in data.deviceVo) {
              if (i != "totalCount") {
                this.allData.deviceData.arr.push({
                  value: data.deviceVo[i],
                  name:
                    i == "online" ? "在线" : i == "offline" ? "离线" : "在线",
                });
              }
            }
            // 事件
            this.allData.eventData.total = data.warningVo.totalCount;
            for (let i in data.warningVo) {
              if (i != "totalCount") {
                this.allData.eventData.arr.push({
                  value: data.warningVo[i],
                  name: i == "handled" ? "已处理" : "待处理",
                });
              }
            }
            // 人员
            this.allData.personData.total = data.personVo.totalCount;
            for (let i in data.personVo) {
              if (i != "totalCount") {
                this.allData.personData.arr.push({
                  value: data.personVo[i],
                  name: i == "in" ? "已安排" : "待分配",
                });
              }
            }
            // 房间
            this.allData.roomData.total = data.resourceVo.totalCount;
            for (let i in data.resourceVo) {
              if (i != "totalCount") {
                this.allData.roomData.arr.push({
                  value: data.resourceVo[i],
                  name: i == "use" ? "在用" : "空闲",
                });
              }
            }

            // 事件列表
            this.eventList = data.eventVos == undefined ? [] : data.eventVos;
          }
        });
    },

    eventTypeOptionsFormat() {
      this.geteEventTypeOptions(this.lvWarning);
      this.geteEventTypeOptions(this.lvNOTIFY);
    },

    geteEventTypeOptions(eventLv) {
      var eventTypeOptions = undefined;
      request
        .get("tob/bEventRecord/getEnumsByEventLv", {
          eventLvValue: eventLv,
        })
        .then((res) => {
          if (res.data.success) {
            var enums = res.data.data;
            var eventTypeOptions = [];

            for (const key in enums) {
              var value = enums[key];
              var enumObject = {
                value: key - 0,
                label: value,
              };
              eventTypeOptions.push(enumObject);
            }

            if (eventLv == this.lvWarning) {
              this.eventTypeOptionsWarning = eventTypeOptions;
            } else if (eventLv == this.lvNOTIFY) {
              this.eventTypeOptionsNOTIFY = eventTypeOptions;
            }
          }
        });
    },

    eventTypeFormat(value) {
      var info = this.getEventTypeInfo(value);
      var lv = info.lv;
      var lable = info.label;
      console.log("lv", lv);
      console.log("lable", lable);
      if (lv == this.lvNOTIFY) {
        return lable;
      } else if (lv == this.lvWarning) {
        return lable + "，请及时关注";
      }
      return lable;
    },
    getEventTypeInfo(value) {
      var object = {
        label: value,
      };

      if (value != undefined) {
        for (let item of this.eventTypeOptionsWarning) {
          console.log("item", item);
          if (item.value === value) {
            object = {
              label: item.label,
              lv: this.lvWarning,
            };

            return object;
          }
        }

        for (let item of this.eventTypeOptionsNOTIFY) {
          if (item.value === value) {
            object = {
              label: item.label,
              lv: this.lvNOTIFY,
            };
            return object;
          }
        }
      }
      return object;
    },
  },
};
</script>

<style lang="scss" scoped>
.homeMonitor {
  .homeMonitor-left {
    .h-l-title {
      color: #333333;
      font-size: 20px;
      font-weight: bold;
      padding-left: 10px;
      margin-bottom: 40px;
      span {
        font-size: 16px;
        font-weight: 300;
      }
    }
    .pie-list {
      display: flex;
      flex-wrap: wrap;
      .p-item {
        flex: 0 0 calc(50% - 20px);
        background: #ffffff;
        box-shadow: 0px 3px 16px -1px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        padding-top: 32px;
        margin-bottom: 20px;
        margin: 0 10px 20px 10px;
        .txt1 {
          font-size: 16px;
          color: #787586;
        }
        .txt2 {
          font-size: 20px;
          color: #333333;
          font-weight: bold;
        }
        .bg-device {
          background: #e1dff4;
        }
        .bg-event {
          background: #efe3f6;
        }
        .bg-person {
          background: #d1e1f9;
        }
        .bg-room {
          background: #d0eefa;
        }
        .pie-title {
          text-align: center;
          margin-bottom: 26px;
          img {
            width: 42px;
            margin-bottom: 10px;
          }
        }
        .pie {
          display: flex;
          justify-content: center;
        }
        .pie-center {
          display: flex;
          margin: 0 20px;
          align-items: center;
          .cl {
            border-top: 1px solid #dadde7;
            border-bottom: 1px solid #dadde7;
            flex: 1;
            padding: 26px 0;
            text-align: center;
          }
          .cc {
            flex: 1;
            text-align: center;
          }
          .cr {
            border-top: 1px solid #dadde7;
            border-bottom: 1px solid #dadde7;
            flex: 1;
            padding: 26px 0;
            text-align: center;
          }
        }
        .pie-num {
          text-align: center;
          padding: 15px;
          border-radius: 0 0 6px 6px;
          margin-top: 26px;
        }
      }
    }
  }
  .homeMonitor-right {
    background: #ffffff;
    box-shadow: 0px 3px 16px -1px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    padding: 20px 0 20px 20px;
    .h-r-title {
      color: #333333;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
      span {
        display: inline-block;
        width: 3px;
        height: 18px;
        background: #7468f0;
        vertical-align: middle;
      }
    }
    .event-list {
      height: 833px;
      max-height: 833px;
      padding-right: 20px;
      overflow: overlay;
      .event-item {
        background: #f6f5fc;
        border-radius: 5px;
        font-size: 16px;
        letter-spacing: 1px;
        padding: 16px;
        margin-bottom: 18px;
        .person {
          color: #787586;
          margin-bottom: 8px;
          span {
            display: inline-block;
            margin-right: 10px;
          }
        }
        .event {
          color: #333;
        }
      }
    }
  }
}
</style>
